
//Overrides need to go before import.
$brand-primary: #26559c;
$font-family-sans-serif: Lato, Tahoma;

$theme-colors: (
  "primary": #26559c,
  "danger": #ff4136
);

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';